@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-neutral-700;
    @apply bg-neutral-100;
    @apply antialiased;
    @apply text-[14px];
  }
}

@media print {
  @page {
    margin: 20mm;
  }
}
/* Stile della scrollbar */
::-webkit-scrollbar {
  width: 8px; /* Larghezza della scrollbar */
  height: 8px; /* Altezza della scrollbar (solo per la scrollbar verticale) */
}

/* Track della scrollbar (parte non selezionata) */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Colore di sfondo della scrollbar */
}

/* Maniglia della scrollbar (parte selezionata)*/
::-webkit-scrollbar-thumb {
  background: #888; /* Colore della maniglia della scrollbar */
}

/* Facoltativo: stile quando la scrollbar è attiva (ad esempio, quando si trascina con il mouse sopra) */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Colore della maniglia della scrollbar durante il passaggio del mouse sopra */
}
